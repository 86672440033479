<template>
    <v-card :loading="loading" :style="`height: ${height + 550}px`">
        <v-card-title ref="title">
            <v-btn small icon @click="closeDialog" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>
                Forecast History
            </h3>
            <v-spacer />
        </v-card-title>
        <v-divider class="ml-4 mr-5" />

        <v-card-text class="mt-6" :style="`height: ${height - 600}px`">
            <v-row
                no-gutters
                style="background-color: #eeeeee"
                class="mt-8 align-center"
                ref="totals"
            >
                <v-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    class="d-flex justify-center"
                >
                    <p class="my-2 font-weight-black">
                        QUOTE PRICE:
                        {{
                            formatCurrency(
                                project.currency,
                                historyQuote.totalCosts
                            )
                        }}
                    </p>
                </v-col>
                <v-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    class="d-flex justify-center"
                >
                    <p class="my-2 font-weight-black">
                        CURRENT BALANCE TO BILL:
                        {{
                            formatCurrency(
                                project.currency,
                                historyQuote.balanceToBill
                            )
                        }}
                    </p>
                </v-col>
            </v-row>

            <v-col cols="12" class="mb-6">
                <v-data-table
                    :headers="quoteHeaders"
                    :items="historyItems"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="mt-0 mx-0 pt-4 pb-0 pb-4 align-center"
                            style="background-color: #eeeeee"
                        >
                            <v-col cols="9"
                                ><h2 class="ml-4">
                                    QUOTE: {{ historyQuote.number }} -
                                    {{ historyQuote.name }}
                                </h2>
                            </v-col>

                            <v-spacer />
                        </v-row>
                    </template>
                    <template v-slot:[`item.title`]="{ item }">
                        <v-col
                            style="background-color: #eee; width: 100%; height: 100%;"
                            class="ma-0 pa-0 ml-n4"
                        >
                            <p
                                class="mt-4 ml-7 d-flex align-center justify-center"
                                style="font-size: 12px; font-weight: 500; color: #5f5f5f;"
                            >
                                <span class="mt-6 mb-n2">{{ item.title }}</span>
                            </p>
                        </v-col>
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.date`]="{ item, header }">
                        <div v-if="item.title == 'FORECASTED'">
                            <v-col v-if="item[header.month]">
                                <!-- <p class="my-1">
                                    {{
                                        Number(
                                            item[header.month].percentage || 0
                                        ).toFixed(1)
                                    }}
                                    %
                                </p> -->

                                <p class="mb-1">
                                    {{
                                        formatCurrency(
                                            project.currency,
                                            item[header.month].amount || 0
                                        )
                                    }}
                                </p>
                            </v-col>
                            <p v-else>
                                No Forecast Exist
                            </p>
                        </div>

                        <div v-if="item.title == 'INVOICED'">
                            <p class="my-1">
                                {{
                                    formatCurrency(
                                        project.currency,
                                        getInvoicedInMonth(
                                            header.month,
                                            historyQuote.id
                                        )
                                    )
                                }}
                            </p>
                        </div>
                        <div v-if="item.title == 'COMPLIANCE PERCENTAGE'">
                            <p class="my-1">
                                {{
                                    historyQuote[header.month] &&
                                    historyQuote[header.month].amount
                                        ? (
                                              (getInvoicedInMonth(
                                                  header.month,
                                                  historyQuote.id
                                              ) *
                                                  100) /
                                              historyQuote[header.month].amount
                                          ).toFixed(1)
                                        : '-'
                                }}
                                %
                            </p>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
    name: 'ForecastHistory',
    props: {
        historyQuote: {
            type: Object,
            required: true,
            default: () => {},
        },
        project: {
            type: Object,
            required: true,
            default: () => {},
        },
        invoices: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
            forecast: {},
            quoteHeaders: [],
            originalQuoteHeaders: [],
            months: [
                'JAN',
                'FEB',
                'MAR',
                'APR',
                'MAY',
                'JUN',
                'JUL',
                'AUG',
                'SEP',
                'OCT',
                'NOV',
                'DEC',
            ],
            prevBalanceToBill: 0,
            maxAmountValue: 0,
            maxPercentageValue: 0,
            height: 0,
            dateToShow: [],
            forecastedPercentage: 0,
            previousMonth: '',
            historyItems: [],
        }
    },

    async mounted() {
        try {
            this.loading = true
            let previousMonth = new Date()
            this.previousMonth = `${previousMonth.getFullYear()}-${String(
                previousMonth.getMonth()
            ).padStart(2, '0')}`

            Object.keys(this.historyQuote).forEach(key => {
                if (key.includes('-')) {
                    this.dateToShow.push(key)
                }
            })
            this.dateToShow = [...new Set(this.dateToShow.sort())]
            const minDate = this.dateToShow[0]
            this.dateToShow = this.monthsBetweenDates(
                moment(minDate),
                moment(previousMonth)
            )

            let yearClasses = {}
            let toggleClass = true

            this.originalQuoteHeaders.push({
                text: '',
                value: 'title',
                align: 'center',
                sortable: false,
                width: 150,
            })

            this.dateToShow.forEach(date => {
                const dateParts = date.split('-')
                const monthIndex = Number(dateParts[1]) - 1
                const year = dateParts[0]
                const dateText = `${this.months[monthIndex]}-${year}`

                if (!yearClasses[year]) {
                    yearClasses[year] = toggleClass
                        ? 'dark-gray-background'
                        : 'gray-background'
                    toggleClass = !toggleClass
                }
                this.originalQuoteHeaders.push({
                    text: dateText,
                    value: 'date',
                    align: 'center',
                    sortable: false,
                    width: 150,
                    type: 'month',
                    month: date,
                })
            })

            this.dateToShow.forEach(date => {
                const dateParts = date.split('-')
                const year = dateParts[0]
                const existent = this.originalQuoteHeaders.find(
                    h => h.text == year
                )
                if (!existent) {
                    this.originalQuoteHeaders.push({
                        text: year,
                        value: 'dateYear',
                        align: 'center',
                        sortable: false,
                        width: 100,
                        type: 'year',
                        year,
                    })
                }
            })
            this.historyItems = [
                { title: 'FORECASTED', ...this.historyQuote },
                { title: 'INVOICED', ...this.invoices },
                { title: 'COMPLIANCE PERCENTAGE', ...{} },
            ]
            console.log(this.historyItems[0])
            this.showMonths()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeDialog() {
            this.$emit('closeDialog')
        },
        formatCurrency(currency, value) {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: currency,
                maximumFractionDigits: 0,
            }).format(value)
        },
        showMonths() {
            this.quoteHeaders = this.originalQuoteHeaders.filter(
                h => h.type != 'year'
            )
            this.viewType = 'month'
        },
        onResize() {
            this.height = window.innerHeight - 150
        },
        monthsBetweenDates(startDate, endDate) {
            let currentDay = startDate
            if (moment(startDate).isAfter(endDate)) {
                currentDay = endDate
            }
            let datesObject = []
            let dates = []
            while (currentDay.isSameOrBefore(endDate)) {
                dates.push(currentDay.format('YYYY-MM'))
                datesObject[currentDay.format('YYYY-MM')] = 0
                currentDay.add(1, 'month')
            }
            return dates
        },
        getInvoicedInMonth(date, quoteId) {
            let totalInvoiced = 0
            if (this.invoices[date]) {
                this.invoices[date].forEach(invoice => {
                    if (invoice.items) {
                        Object.keys(invoice.items).forEach(qId => {
                            if (qId == quoteId) {
                                const items = invoice.items[qId]
                                items.forEach(item => {
                                    totalInvoiced +=
                                        (item.billedQty -
                                            (item.creditQty || 0)) *
                                        item.pricePerUnit
                                })
                            }
                        })
                    }
                })
            }

            return totalInvoiced
        },
    },
}
</script>
